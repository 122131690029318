import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import SplashScreen from "./SplashScreen";

const Index = () => {
    return (
        <FixedHeaderUserLayout children={<SplashScreen />} />
    );
};

export default Index;
