import React, { useEffect, useState, useContext } from "react";
import RedirectCheck from "../../routes/RedirectCheck";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assests/icons/logo.png";
import logo2 from '../assests/icons/logo-green.png'
import menuIcon from "../assests/icons/menu.png";
import close from "../assests/icons/close.png";
import profile from "../assests/icons/profile.png";
import Toast from "../common/Toast/Toast";
import { headerText, storageKeys, signup, common, userType } from "../../utils/constants";
import { homeNavigation } from "../../utils/homeNavigation";
import { logout } from "../../utils/ApiClient";
import globalContext from "../../context/globalContext";
import { clearLocalStorageExceptOID } from "../../utils/clearStorageData";
import Footer from "../common/Footer/Footer";
import "./FixedHeaderUserLayout.css";

interface Props {
  children: React.ReactNode;
  hideMyAccButton?: boolean;
}

const FixedHeaderUserLayout: React.FC<Props> = ({
  children,
  hideMyAccButton,
}) => {
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const { globalErrorHandler, menu, setMenu }: any = useContext(globalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const token = localStorage.getItem(storageKeys.TOKEN);
  const hideMyAccountButton = hideMyAccButton ? true : false;
  const isNewVersion = location.pathname.includes('/v2');
  const onBoardPages = currentPath === '/onboarding-prt-coordinator' || currentPath === "/prt-coordinator-login"
  const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)

  const handleHomeNavigation = (): void => {
    if (token) {
      if (userTypeName === userType.CUSTOMER) {
        navigate("/account-info");
      } else if (userTypeName === userType.PRT_USER) {
        navigate("/prt-dashboard");
      } else {
        navigate("/prt-manager-dashboard")
      }
    } else {
      homeNavigation();
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      clearLocalStorageExceptOID(storageKeys.ORGANIZATION_ID);
      navigate("/login");
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const handleNavigate = () => {
    navigate("/login");
  };

  const goToSettings = () => {
    navigate('/prt-settings')
  }

  const goToPRTManagerDashboard = () => {
    navigate('/prt-manager-dashboard')
  }

  useEffect(() => {
    if (currentPath.startsWith("/chat/") || currentPath === "/" || onBoardPages || userTypeName === (userType.PRT_MANAGER) || userTypeName === (userType.PRT_USER) || isNewVersion) {
      setShowFooter(false);
    }
  }, []);

  return (
    <div className="fixed-header-component fixed-header-layout">
      <RedirectCheck />
      <header className={`common-header ${(userTypeName === userType.PRT_MANAGER) || (userTypeName === userType.PRT_USER) ? "smaller-fixed-header" : "fixed-header"}`}>
        <div className="header">
          <div className="header-content-left">
            <img
              src={isNewVersion ? logo2 : logo}
              alt="logo"
              className="fixed-header-logo"
              onClick={handleHomeNavigation}
            />
            {
              (!onBoardPages && !userTypeName || userTypeName === userType.CUSTOMER) && <span className="header-title subtitle">{headerText.TITLE}</span>
            }
          </div>
        </div>
        {
          isNewVersion ? <div className="right-alinged-btns">
            <span className="info-text">{"Learn More"}</span>
            <button className="sign-in-btn">{"SIGN IN"}</button>
          </div> : <>
            {
              currentPath === '/onboarding-prt-coordinator' || currentPath === "/prt-coordinator-login" ?
                <div className="onboarding-buttons">
                  <span className={currentPath === "/onboarding-prt-coordinator" ? "active-page" : ""} onClick={()=>navigate('/onboarding-prt-coordinator')}>{"Sign up"}</span>
                  <span className={currentPath === "/prt-coordinator-login" ? "active-page" : ""} onClick={()=>navigate('/prt-coordinator-login')}>{"SIGN IN"}</span>
                </div> : <>{currentPath === "/" && (
                  <img
                    src={menu ? close : menuIcon}
                    alt="menu-icon"
                    className="menu-icon"
                    onClick={() => setMenu(!menu)}
                  />
                )}
                  {hideMyAccountButton || !token ? (
                    <>
                      {currentPath === "/" && (
                        <div onClick={handleNavigate} className="nav-link-div">
                          <span className="header-nav-link">{signup.LOGIN_TEXT1}</span>
                          <span className="header-nav-link">{signup.LOGIN_TEXT2}</span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {
                        userTypeName === userType.PRT_MANAGER ? (
                          <div className="prt-buttons">
                            {
                              currentPath === "/prt-manager-dashboard" ? <span className="prt-text" onClick={goToSettings}>{common.PRT_SETTINGS}</span> : <span className="prt-text" onClick={goToPRTManagerDashboard}>{common.PRT_DASHBOARD}</span>
                            }
                            <button className="prt-logout-btn" onClick={handleLogout}>{"Log Out"}</button>
                          </div>
                        ) : <>
                          {currentPath === "/account-info" || userTypeName === userType.PRT_MANAGER || userTypeName === userType.PRT_USER ? (
                            <div className="button-box">
                              <button className="logout-button" onClick={handleLogout}>
                                {"Logout"}
                              </button>
                            </div>
                          ) : (
                            <div className="user-div" onClick={handleHomeNavigation}>
                              <img src={profile} alt="user" className="avatar" />
                              <span className="user-div-text">{common.MY_ACCOUNT}</span>
                            </div>
                          )} </>
                      }
                    </div>
                  )}</>
            }
          </>
        }

      </header>
      <Toast />
      <div className="content">{children}</div>
      {showFooter === true && <div className="footer-div"><Footer /></div>}
    </div>
  );
};

export default FixedHeaderUserLayout;
