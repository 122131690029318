import React, { useState, useEffect, useContext } from "react";
import {
  signup,
  common,
  LS_PHONE_NUMBER,
  callPage,
  storageKeys,
} from "../../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import globalContext from "../../../context/globalContext";
import { register } from "../../../utils/ApiClient";
import Loader from "../../common/Loader/Loader";
import CredentialStorage from "../../../utils/CredentialStorage";
import { validate } from "./validate";
import { EmergencyDisclaimer } from "../../common/EmergencyDisclaimer/EmergencyDisclaimer";
import '../../../styles/authFormsStyles.scss'
import './Register.scss'

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [callSupport, setCallSupport] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { setGlobalError, globalErrorHandler }: any = useContext(globalContext);
  const [phoneNumber, setPhoneNumber] = useState<string>(callPage.NUMBER);

  const navigate = useNavigate();

  useEffect(() => {
    setPhoneNumber(localStorage.getItem(LS_PHONE_NUMBER) ?? callPage.NUMBER);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNavigate = () => {
    const flow = localStorage.getItem(storageKeys.FLOW)
    if(flow && flow === common.WORKLIFE_REQUEST ){
      const pathName = '/worklife-form'
      navigate(`/login?redirect_url=${pathName}`)
    } else navigate("/login");
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const flow = localStorage.getItem(storageKeys.FLOW);
    const organization_id = localStorage.getItem(storageKeys.ORGANIZATION_ID);
    const organization_name = localStorage.getItem(storageKeys.COMPANY);
    const validation = validate(formData);
    if (validation) {
      setErrorMessage(validation);
      setError(true);
    } else {
      setError(false);
      setLoading(true);
      setDisabled(true);
      const data = {
        first_name: formData.firstname,
        last_name: formData.lastname,
        email: formData.email,
        flow: flow,
        organization_id: organization_id,
        organization_name: organization_name,
      };
      try {
        const response: any = await register(data);
        CredentialStorage.login(response);
        setDisabled(false);
        setLoading(false);
        if (flow === common.WORKLIFE_REQUEST) {
          navigate('/worklife-form')
        } else
          navigate("/age-gate");
      } catch (error: any) {
        globalErrorHandler(error);
        if (error.response?.status === 404) {
          setCallSupport(true);
        } else if (error.response?.status === 400) {
          setGlobalError(error.response.data.message);
          setIsChecked(false);
          setLoading(false);
          setDisabled(false);
        }
      }
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checked = event.target.checked;
    setIsChecked(checked);
    if (checked) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!isChecked) {
      setDisabled(true);
    }
  }, [isChecked]);

  return (
    <div className="register-page discaimer-view">
      {callSupport ? (
        <div className="loader-container">
          <span>{common.CONTCAT_SUPPORT}</span>
        </div>
      ) : (
        <div className="register-view">
          {loading ? (
            <Loader />
          ) : (
            <div className="register-content">
              <EmergencyDisclaimer phoneNumber={phoneNumber} />
              <span className="subtitle">{signup.SUBTITLE}</span>
              <span className="title">{signup.TITLE}</span>
              <form className="signup-form">
                <div className="form-input">
                  <div className="input-div">
                    <label>{common.FIRST_NAME}</label>
                    <input
                      type="text"
                      name="firstname"
                      className="signup-page-input"
                      placeholder={common.FIRST_NAME_PLACEHOLDER}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="input-div">
                    <label>{common.LAST_NAME}</label>
                    <input
                      type="text"
                      name="lastname"
                      className="signup-page-input"
                      placeholder={common.LAST_NAME_PLACEHOLDER}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>
                <div className="input-div">
                  <label>{signup.EMAIL_ADDRESS}</label>
                  <small>{signup.EMAIL_SUBTITLE}</small>
                  <input
                    type="email"
                    name="email"
                    className="signup-page-email-input"
                    placeholder={signup.EMAIL_ADDRESS_PLACEHOLDER}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                {error && <div className="error">{errorMessage}</div>}
                <label className="check-box-label">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div>
                    {`${signup.CHECKBOX_LABEL}`}
                    <Link to={"/terms-of-service"}>
                      <span>{signup.TERMS_OF_SERVICE}</span>
                    </Link>
                  </div>
                </label>
                <button
                  onClick={handleSubmit}
                  className={
                    disabled
                      ? "large-green-button-disabled"
                      : "large-green-button"
                  }
                  disabled={disabled}
                >
                  {signup.BUTTON}
                </button>
                <span className="nav-link nav-margin" onClick={handleNavigate}>
                  {signup.LOGIN_TEXT}
                </span>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SignUp;
