import React, { useState } from 'react'
import {onBoardLogin} from '../../../../utils/constants'
import { logIn } from "../../../../utils/ApiClient";
import { useNavigate } from 'react-router-dom';
import './PRTCoordinatorLogIn.scss'

const PRTCoordinatorLogIn = () => {
    const [formData, setFormData] = useState({
        email: "",
    });
    const [disabled, setDisabled] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const navigate = useNavigate()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setError(false);
        setDisabled(false);
        const { name, value } = e.currentTarget;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleNavigation = () => {
        navigate('/onboarding-prt-coordinator');
    };

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const resetErrors = (): void => {
        setError(false);
        setErrorMessage("");
        setSuccessMessage("");
    };

    const handleSubmit = async (): Promise<any> => {
        resetErrors();
        setDisabled(true);
        if (!formData.email.trim() || !emailRegex.test(formData.email)) {
            setError(true);
            setErrorMessage("Please enter a valid email.");
        } else {
            const emailObj = {
                email: formData.email,
            };
            try {
                const resolve: any = await logIn(emailObj);
                setSuccessMessage(resolve.data.message); 
                setDisabled(false);
            } catch (error: any) {
                setErrorMessage(error.response?.data.message);
                setError(true)
            }
        }
    };

    return (
        <div className="onboard-login-page margin-content">
            <span className="title">{onBoardLogin.title}</span>
            <span className="subtitle">{onBoardLogin.subtitle}</span>
            <form className="signup-form">
                <div className="input-div">
                    <label>{onBoardLogin.emailLabel}</label>
                    <input
                        type="email"
                        name="email"
                        className="signup-page-email-input"
                        placeholder={onBoardLogin.emailPlaceHolder}
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </div>
                {successMessage && <div className="message success-message">{successMessage}</div>}
                {error && <div className="message error-message-red">{errorMessage}</div>}
                <button
                    onClick={handleSubmit}
                    className={
                        disabled ? "large-green-button-disabled" : "large-maroon-button"
                    }
                    disabled={disabled}
                >
                    {onBoardLogin.buttonName}
                </button>
            </form>
            <span className="nav-link nav-margin" onClick={handleNavigation}>
                {onBoardLogin.link}
            </span>
        </div>
    );
};

export default PRTCoordinatorLogIn