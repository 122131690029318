import { useContext, useEffect, useRef, useState } from "react"
import { PRT_Email_Notification } from "../../../utils/constants"
import { addPRTConfiguration, getPRTConfiguration } from "../../../utils/ApiClient"
import globalContext from "../../../context/globalContext"
import Loader from "../../common/Loader/Loader"

const EmailNotification = () => {
    const [email, setEmail] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const { globalErrorHandler }: any = useContext(globalContext)
    const effectRef = useRef(false)

    const getEmail = async (): Promise<void> => {
        try {
            const response = await getPRTConfiguration()
            if (
                response.data.data.prt_configuration?.email_notification
            ) {

                setEmail(response.data.data.prt_configuration.email_notification);
                setLoading(false)
            } else
                setEmail("")
                setLoading(false)
        } catch (error) {
            setEmail("")
            setLoading(false)
            globalErrorHandler(error)
        }
    }

    useEffect(() => {
        if (effectRef.current === false) {
            getEmail()
        }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                setSuccessMessage("")
            }, 5000)
        }
    }, [successMessage])

    const handleSave = async (): Promise<void> => {
        const response = await addPRTConfiguration(email)
        setSuccessMessage(response.data.message)
    }

    return (
        <>
            {
                loading ? <Loader /> : <div className="setting-component">
                    <span className="setting-title">{PRT_Email_Notification.title}</span>
                    <span className="setting-desciption">{PRT_Email_Notification.description}</span>
                    <input className="input-box margin-input-box" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <button className="light-green-button button-margin" onClick={handleSave}>{"Save"}</button>
                    {
                        successMessage && <span className="success">{successMessage}</span>
                    }
                </div>
            }
        </>
    )
}

export default EmailNotification