import React, { useState, useContext } from 'react'
import { AxiosResponse } from 'axios';
import globalContext from '../../../context/globalContext';

interface DataProps {
    list: any[];
    hideButtons: boolean;
    deleteAPI?: (id: number) => Promise<AxiosResponse<any, any>>;
    fetchAPI?: (term: string) => void;
    editAPI?: ((id: number, name: string) => Promise<any>) | ((id: number, name: string, email: string) => Promise<any>);
    setSuccessMessage?: (message: string) => void;
    setLoading?: (loading: boolean) => void
}

const DataList: React.FC<DataProps> = ({ list, hideButtons, deleteAPI, fetchAPI, editAPI, setSuccessMessage, setLoading }) => {
    const [editFullName, setEditFullName] = useState<string>("")
    const [editingId, setEditingId] = useState<number | null>(null);
    const [editEmail, setEditEmail] = useState<string>("")
    const { globalErrorHandler }: any = useContext(globalContext)

    const deleteRecord = async (id: number): Promise<void> => {
        try {
            const response = await deleteAPI?.(id);
            setSuccessMessage?.(response?.data.message)
            setLoading?.(true)
            fetchAPI?.("")
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    const handleEdit = (id: number, fullName: string, email?: string) => {
        setEditingId(id);
        setEditFullName(fullName);
        setEditEmail(email ?? "");
    };

    const editRecord = async (id: number): Promise<void> => {
        try {
            if (editEmail) {
                const response = await editAPI?.(id, editFullName ?? "", editEmail)
                setSuccessMessage?.(response?.data.message)
                setLoading?.(true)  
                fetchAPI?.("")
                setEditingId(null)
            } else {
                const response = await editAPI?.(id, editFullName, "")
                setSuccessMessage?.(response?.data.message)
                setLoading?.(true)
                fetchAPI?.("")
                setEditingId(null)
            }
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    return (
        <>
            {
                list?.map((item: any, index: number) => {
                    return <div key={item.id} className={`common-action-bar ${index % 2 === 0 ? "transperent-bar" : "white-action-bar"}`}>
                        {editingId === item.id ? (
                            <>
                                <input
                                    type="text"
                                    value={editFullName ?? ""}
                                    onChange={(e) => setEditFullName?.(e.target.value)}
                                    className="input-box"
                                />
                                {item.email && <input
                                    type="text"
                                    value={editEmail ?? ""}
                                    onChange={(e) => setEditEmail?.(e.target.value)}
                                    className="input-box" />}
                            </>
                        ) : (
                            <div className='title-line'>
                                {item.full_name && <span>{item.full_name}</span>}
                                {item.organization_id && <span>{item.organization_id}</span>}
                                {item.email && <span>{item.email}</span>}
                            </div>
                        )}
                        {editingId === item.id ? (
                            <>{
                                !hideButtons && <div className="button-box">
                                    <span onClick={() => setEditingId?.(null)} className="line-button">{"Cancel"}</span>
                                    <button onClick={() => editRecord?.(item.id)} className="green-line-button">{"SAVE"}</button>
                                </div>
                            }</>
                        ) : (
                            <>
                                {
                                    !hideButtons && <div className="button-box">
                                        <span onClick={() => handleEdit?.(item.id, item.full_name, item.email)} className="line-button">{"Edit"}</span>
                                        <span onClick={() => deleteRecord(item.id)} className="line-button">{"Delete"}</span>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                })
            }
        </>
    )
}

export default DataList