import React, { useState, useEffect, useContext, useRef } from 'react'
import { getFormatDateString, getAppointString } from '../../../utils/helperFunctions'
import { PRTUserCasesType, storageKeys, userType, unAssignDialog, CaseStatus, PRTManagerCaseType } from '../../../utils/constants';
import CustomDropdownMenu from '../../common/DropdownMenu/DropdownWithConfirmationBar';
import ConfirmDialogBox from '../../common/DialogBox/ConfirmDialogBox';
import { getPRTSpecialistList, unAssignCoordinator } from '../../../utils/ApiClient';
import globalContext from '../../../context/globalContext';
import { PRTCaseDetails } from '../../../interfaces/PRTCaseDetails';

interface CaseDetailsProps {
    activeCaseInfo: PRTCaseDetails | undefined;
    caseStatus: string;
    setCaseStatus: (status: string) => void;
    activeCase: string;
    caseLoading: boolean;
    additionalProviders?: any;
    setSpecialist: (specialist: string) => void;
    specialist: string;
    casesTypeName: string;
    setCasesTypeName: (caseType: string) => void;
    setCaseLoading: (loading: boolean) => void;
    setCaseReopen?: (open: boolean) => void;
    setIsUnassign?: (assign: boolean) => void;
}

const CaseDetails: React.FC<CaseDetailsProps> = ({ activeCaseInfo, caseStatus, activeCase, setCaseStatus, caseLoading, setSpecialist, specialist, casesTypeName, additionalProviders, setCasesTypeName, setCaseLoading, setCaseReopen, setIsUnassign }) => {
    const [status, setStatus] = useState<string>("");
    const [specialistName, setSpecialistName] = useState("");
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [specialistList, setSpecialistList] = useState<any>([])
    const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)
    const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
    const isManager = userTypeName === userType.PRT_MANAGER
    const { globalErrorHandler }: any = useContext(globalContext)
    const effectRef = useRef(false)

    const fetchSpecialist = async (): Promise<void> => {
        try {
            const response = await getPRTSpecialistList()
            setSpecialistList(response.data.data.prt_specialists)
        } catch (error) {
            setSpecialistList([])
            globalErrorHandler(error)
        }
    }

    useEffect(() => {
        setSpecialistName(specialist)
    }, [specialist])

    useEffect(() => {
        setStatus(caseStatus)
    }, [caseStatus])

    useEffect(() => {
        if (effectRef.current === false && userTypeName === userType.PRT_USER) {
            fetchSpecialist()
        }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (successMessage) {
            setCaseStatus(status);
            setTimeout(() => {
                setSuccessMessage("")
                if (setIsUnassign) setIsUnassign(true)
                if (status === CaseStatus.RE_OPENED.label && casesTypeName === PRTUserCasesType.CLOSED.label) {
                    setCasesTypeName(PRTUserCasesType.MY_CASES.label)
                    if (setCaseReopen) { setCaseReopen(true) }
                    setCaseLoading(true)
                }
            }, 5000)
        }
    }, [successMessage])

    const handleCancel = () => {
        setOpenDialog(false)
    }

    const handleUnAssign = async (): Promise<void> => {
        setIsDialogButtonDisabled(true)
        try {
            const response = await unAssignCoordinator(activeCaseInfo?.file_number || 0);
            setSuccessMessage(response.data.message)
            setIsDialogButtonDisabled(false)
            handleCancel()
        } catch (error) {
            globalErrorHandler(error)
            setIsDialogButtonDisabled(false)
            handleCancel()
        }
    }

    return (
        <>{
            (activeCaseInfo && !caseLoading) && <React.Fragment>
                <div className='case-sub-section'>
                    <div className='case-staus-section'>
                        <div className='case-status-content'>
                            <span>Eapx Case: {activeCaseInfo?.id}</span>
                            <span>Case status: {caseStatus}</span>
                            <span>ICT Case Manager: N/A</span>
                            <span>Last modified: {getFormatDateString(activeCaseInfo?.last_modified).formattedDate}</span>
                            {
                                isManager && <div className="details">
                                    <span>Date Opened: {getFormatDateString(activeCaseInfo?.date_opened).formattedDate}</span>
                                    <span>Coordinator on Case: {activeCaseInfo?.coordinator_on_case}</span>
                                    <span>Assigned Specialist: {activeCaseInfo?.assigned_specialist}</span>
                                </div>
                            }
                        </div>
                        {
                            isManager  && PRTManagerCaseType.ALL_CASES.label === casesTypeName ? <div className='btn-section'><button className='light-green-button' onClick={() => setOpenDialog(true)}>{"UN-ASSIGN COORDINATOR"}</button> </div>
                                : <> {
                                    !isManager && <div className='case-status-content'>
                                        <CustomDropdownMenu
                                            options={CaseStatus}
                                            name={"eapx_file_status"}
                                            stateChange={setStatus}
                                            state={status}
                                            showButtons={true}
                                            fileNo={activeCaseInfo?.file_number}
                                            showPopUp={true}
                                            setSuccessMessage={setSuccessMessage}
                                            setCaseStatus={setCaseStatus}
                                            caseStatus={caseStatus}
                                            casesTypeName={casesTypeName}
                                            activeCase={activeCase}
                                            additionalProviders={additionalProviders}
                                        />
                                        <CustomDropdownMenu
                                            options={specialistList}
                                            name={"assigned_specialist"}
                                            stateChange={setSpecialistName}
                                            specialist={specialist}
                                            state={specialistName}
                                            showButtons={true}
                                            fileNo={activeCaseInfo?.file_number}
                                            showPopUp={true}
                                            setSuccessMessage={setSuccessMessage}
                                            casesTypeName={casesTypeName}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                    {
                        successMessage && <span className='success success-msg-section'>{successMessage}</span>
                    }
                </div>
                <div className='case-sub-section'>
                    <span>First Name : {activeCaseInfo?.patient_information.first_name ?? "-"}</span>
                    <span>Gender : {activeCaseInfo?.patient_information?.gender ?? "-"}</span>
                    <span>State : {activeCaseInfo?.patient_information?.state ?? "-"}</span>
                    <span>Zipcode : {activeCaseInfo?.patient_information?.zipcode ?? "-"}</span>
                </div>
                <div className='case-sub-section'>
                    <span>Client Company : {activeCaseInfo?.company_name ?? "-"} </span>
                    <span>Presenting condition : {activeCaseInfo?.presenting_condition.name ?? "-"} </span>
                    <span>Prefered modality : {activeCaseInfo?.preferred_modality ?? "-"} </span>
                    <span>Distance willing to travel : {activeCaseInfo?.distance_willing_to_travel ?? "-"} </span>
                    <span>Provider preferences: {activeCaseInfo?.provider_preference ?? "-"} </span>
                    <span>Appointment date preference: {getAppointString(activeCaseInfo?.appointment_date_preference)} </span>
                </div>
            </React.Fragment>
        }
            <ConfirmDialogBox
                title={unAssignDialog.title}
                content={unAssignDialog.content}
                confirmLabel={unAssignDialog.confirmLabel}
                onConfirm={handleUnAssign}
                handleClose={handleCancel}
                openDialogBox={openDialog}
                buttonDisabled={isDialogButtonDisabled} />
        </>
    )
}

export default React.memo(CaseDetails)