import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from '../../assests/images/img1.jpg'
import Image2 from '../../assests/images/img2.jpg'
import Image3 from '../../assests/images/img3.jpg'
import Image4 from '../../assests/images/img4.jpg'
import Image5 from '../../assests/images/img5.jpg'
import Image6 from '../../assests/images/img6.jpg'
import './SplashScreen.scss'

const SplashScreen: React.FC = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='splash-screen-page'>
      <Slider {...settings}>
        <div className='image-box'>
          <img src={Image1} alt='img' />
        </div>
        <div className='image-box'>
          <img src={Image2} alt='img' />
        </div>
        <div className='image-box'>
          <img src={Image3} alt='img' />
        </div>
        <div className='image-box'>
          <img src={Image4} alt='img' />
        </div>
        <div className='image-box'>
          <img src={Image5} alt='img' />
        </div>
        <div className='image-box'>
          <img src={Image6} alt='img' />
        </div>
        <div className='image-box'>
          <img src={Image1} alt='img' />
        </div>
      </Slider>
    </div>
  )
}

export default SplashScreen